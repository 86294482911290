import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"

const Submitted = () => {
    return(
        <Layout hideHeader={true}>
            <div className="bg-gray-100 h-screen w-full">
                <div className="container mx-auto p-8 flex items-center justify-center">
                    <div className="xl:w-5/12 p-8 border rounded-lg bg-white">
                        <h2 className="text-center text-4xl mb-3 tracking-tight">Thank you for contacting us</h2>
                        <p className="font-light text-center mb-4">We appreciate you contacting us. One of our colleagues will get back in touch with you soon! Have a great day!</p>
                        <Link to="/" className="block text-center bg-dark bg-opacity-10 hover:bg-primary hover:text-white transition-basic px-10 py-3 uppercase text-white text-sm tracking-widest">
                            Back to Home
                        </Link>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Submitted

